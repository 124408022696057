import { Checkbox, IconButton } from '@material-ui/core';
import { ChevronLeft, ChevronRight, MoreVert, Redo, KeyboardHide, Settings, Inbox, People, LocalOffer } from '@material-ui/icons';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Section from './Section';
import React, { useEffect, useState } from 'react';
import "./EmailList.css";
import EmailRow from './EmailRow';
import { database } from '../firebase';

function EmailList() {

    const [emails, setEmails] = useState([]);

    useEffect(() => {
        database.collection('emails').orderBy('timestamp', 'desc').onSnapshot(snapshot => setEmails(snapshot.docs.map((doc) => 
        ({
            id: doc.id,
            data: doc.data()
        }))));
    }, []);

    return (
        <div className="emailList">
            <div className="emailList__settings">
                <div className="emailList__settingsLeft">
                    <Checkbox/>
                    <IconButton>
                        <ArrowDropDown/>
                    </IconButton>
                    <IconButton>
                        <Redo/>
                    </IconButton>
                    <IconButton>
                        <MoreVert/>
                    </IconButton>
                </div>
                <div className="emailList__settingsRight">
                    <IconButton>
                        <ChevronLeft/>
                    </IconButton>
                    <IconButton>
                        <ChevronRight/>
                    </IconButton>
                    <IconButton>
                        <KeyboardHide/>
                    </IconButton>
                    <IconButton>
                        <Settings/>
                    </IconButton>
                </div>
            </div>
            <div className="emailList__sections">
                <Section Icon={Inbox} title="primary" color="red" selected={true} />
                <Section Icon={People} title="Social" color="#1A73E8" />
                <Section Icon={LocalOffer} title="Promotions" color="green" />
            </div>

            <div className="emailList__list">
                {emails.map(({ id, data: { to, subject, message, timestamp } }, index) =>
                    <EmailRow 
                        key={index} 
                        id={id}
                        title={to}
                        subject={subject}
                        description={message}
                        time={new Date(timestamp?.seconds * 1000).toUTCString()}
                    />
                )}
                <EmailRow title="Twitch" subject="Hello How are yyu" description="This is a test" time="10pm"/>
                <EmailRow title="Twitch" subject="Hello How are yyu" description="This is a test This is a test This is a test" time="10pm"/>
            </div>
        </div>
    )
}


export default EmailList
