import { Button } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { closeSendMessage } from '../features/mailSlice';
import { database } from '../firebase';
import firebase from 'firebase';
import "./SendMail.css"
function SendMail() {

    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    const dispatch = useDispatch();

    const onSubmit = (data) => {
        console.log(data);
        database.collection('emails').add({
            to: data.to,
            subject: data.subject,
            message: data.message,
            timestamp: firebase.firestore.FieldValue.serverTimestamp()
        });

        dispatch(closeSendMessage());
    };

    return (
        <div className="sendMail">
            <div className="sendMail__header">
                <h3>New Message</h3>
                <Close onClick={() => dispatch(closeSendMessage())} className="sendMail__close"/>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
                <input 
                    name="to" 
                    type="email" 
                    placeholder="To" 
                    {...register("to", { required: true})}
                />
                {errors.to && <p className="sendMail__error">To is required!</p>}
                <input 
                    name="subject" 
                    type="text" 
                    placeholder="Subject" 
                    {...register("subject", { required: true})}
                />
                {errors.subject && <p className="sendMail__error">{errors.subject}</p>}
                <input 
                    name="message" 
                    type="text" 
                    placeholder="Message" 
                    className="sendMail__message" 
                    {...register("message", { required: true})}
                />
                {errors.message && <p className="sendMail__error">{errors.message}</p>}
                <div className="sendMail__options">
                    <Button 
                        className="sendMail__send"
                        variant="contained"
                        color="primary"
                        type="submit">Send</Button>
                </div>
            </form>
        </div>
    )
}

export default SendMail
