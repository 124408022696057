import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import { Avatar, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import NotificationIcon from '@material-ui/icons/Notifications';
import AppIcon from '@material-ui/icons/Apps';
import "./Header.css";
import { useDispatch, useSelector } from 'react-redux';
import { logout, selectUser } from '../features/userSlice';
import { auth } from '../firebase';

function Header() {

    const user = useSelector(selectUser);

    const dispatch = useDispatch();

    const signOut = () => {
        auth.signOut().then(() => {
            dispatch(logout());
        }).catch(() => {});
    };

    return (
        <div className="header">
            <div className="header__left">
                <IconButton>
                    <MenuIcon />                    
                </IconButton>
                <img 
                        src="https://i.pinimg.com/originals/ae/47/fa/ae47fa9a8fd263aa364018517020552d.png" 
                        alt=""/>
            </div>

            <div className="header__middle">
                <SearchIcon />
                <input type="text" placeholder="Search mail" />
                <ArrowDropDownIcon className="header___inputCaret"/>
            </div>

            <div className="header__right">
                <IconButton>
                    <AppIcon />
                </IconButton>
                <IconButton>
                    <NotificationIcon />
                </IconButton>
                <Avatar onClick={signOut} src={user?.photoUrl}/>
            </div>
        </div>
    )
}

export default Header
