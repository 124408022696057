import firebase from 'firebase';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD_vGgWOtflhL5INbKXm4FM0hG1nNq2uIw",
    authDomain: "clone-5fc17.firebaseapp.com",
    projectId: "clone-5fc17",
    storageBucket: "clone-5fc17.appspot.com",
    messagingSenderId: "763892497636",
    appId: "1:763892497636:web:e81cbfd0c1df1fd62740f1",
    measurementId: "G-HZSNP5MVZF"
  };

  const firebaseApp = firebase.initializeApp(firebaseConfig);
  const database = firebaseApp.firestore();
  const auth = firebaseApp.auth();
  const provider = new firebase.auth.GoogleAuthProvider();

  export { database, auth, provider };